import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PrimaryButton from "../../components/swe/primary-button";
import SecondaryButton from "../../components/swe/secondary-button";
import InfoAlert from "../../components/swe/info-alert";
import DocumentAlreadyVerifiedAlert from "../../components/qdi/alerts/document-already-verified-alert";
import useGlobalState from "../../hooks/useGlobalState";
import { AssociateQDIUserAccountBuilder, DocumentVerificationError, DocumentAlreadyVerifiedError, verifyBirthCertificate, XBody } from "../../services/x-api";
import DocumentInvalidAlert from "../../components/qdi/alerts/document-invalid-alert";
import DocumentVerificationFailedAlert from "../../components/qdi/alerts/document-verification-failed-alert";
import RelyingPartyBuilder from "../../utils/RelyingPartyBuilder";
import * as Constants from "../../constants"
import { MandatoryValidator } from "../../utils/validation/validator";
import Product from "../../components/qdi/product";
import SelectForm from "../../components/qdi/form/select-form";
import BirthCertificateFields from "../../components/qdi/form/birth-certificates/birth-certificate-fields";
import RegDateBirthCertificateFields from "../../components/qdi/form/birth-certificates/regdate-birth-certificate-fields";
import VariLengthBirthCertificateFields from "../../components/qdi/form/birth-certificates/varilength-birth-certificate-fields";
import MFAHandler from "../../utils/MFAHandler";
import { extractIpn, updateLocalStorageSessionTokenIpnAndStatus, updateSessionTokenIpn, determineCorrectIpnValue } from "../../utils/helpers";
import VerifyYourIdentityConsentAlert from "../../components/qdi/alerts/verifying-identity-consent-alert";
import DoBMismatchAlert from "../../components/qdi/alerts/dob-mismatch-alert";
import ThirdDocDoBMismatchAlert from "../../components/qdi/alerts/third-doc-dob-mismatch-alert copy";


export const BirthCertificateForm = () => {
  const navigate = useNavigate();
  const { globalState, saveGlobalState } = useGlobalState();
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const focusRef = useRef(null);
  const { state } = useLocation();
  const ipnLevel = state && state.ipnLevel;
  const dlaUser = state && state.dlaUser;

  let isThirdDoc = (ipnLevel === "ip2" || ipnLevel === "IP2") && dlaUser ? true : false;

  //window.sessionStorage.setItem("QDI-IPE", "IP1P:CL1");

  useEffect(() => {
    (async () => {

      try {
        if (!isAuthenticated) {
          // Throw exception if user not authenticated
          throw new Error("User is not authenticated!");
        }
        await getAccessTokenSilently({ ignoreCache: true });
        const claims = await getIdTokenClaims();
        const claimsIpnFromAcr = extractIpn(claims!['http://oauth.tmr.qld.gov.au'].acr);
        const ipn = determineCorrectIpnValue(claimsIpnFromAcr!, user!['http://oauth.tmr.qld.gov.au'].ipn);

        if (Constants.DEBUG) {
          console.log('users ipn ->', ipn);
          console.log('%c[BirthCertificateForm] isAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;',
            isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
        }

        const sessionToken = localStorage.getItem('session_token');
        saveGlobalState({ sessionToken: JSON.parse(sessionToken == null ? "" : sessionToken) });
        if (globalState.sessionToken.ipn !== "ip1p_nc" && ipn) globalState.sessionToken.ipn = ipn;

        // Check if user has completed this step
        if ( (!dlaUser && globalState.sessionToken.ipn !== "ip1p")
            || (dlaUser && (dlaUser.result === 'Eligible' || dlaUser.result === 'Not eligible'))
              || (dlaUser && dlaUser.result === 'TMR document required')
                || (user && user.email_verified === false)) {

          if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', "Step blocked. Redirecting to uplift"); }
          navigate("/ip-uplift", { replace: true });
        }
      }

      catch (error) {
        if (Constants.DEBUG) { console.log('%c%s', 'color: #c00;', error); }
        // const rp = new RelyingPartyBuilder().withRelyingParty(Constants.AUTH0_UNAUTHORISED_PATH).build();
        // window.location.href = rp;
        navigate("/unauthorised", { replace: true });
      }

    })();
  }, []);

  useEffect(() => {
    focusRef?.current?.focus();
  }, []);

  // validators
  let mandatoryValidator = new MandatoryValidator();

  const [registrationState, setRegistrationState] = useState('');
  const [registrationStateValid, setRegistrationStateValid] = useState(true);

  const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
  const [dobMismatch, setDobMismatch] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [documentInvalid, setDocumentInvalid] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [counter, setCounter] = useState(0);
  const alertRef = useRef(null);

  useEffect(() => {
    if (verificationFailed || documentInvalid || documentAlreadyVerified || dobMismatch) {
      alertRef?.current?.focus();
    }

    if (counter > 4) {
        navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
    }
  }, [verificationFailed, documentInvalid, documentAlreadyVerified, dobMismatch]);

  //These 3 states requires CertNumber and RegNumber to be in type of number
  const statesRequireIdAsNumber = ['NSW', 'VIC', 'TAS'];

  const verifyDocument = async () => {
    setAlertVisibility(false, false, false, false); //Resets alert visibility

    if (counter > 4) {
      navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
    }

    try {
      const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
      const idToken = await getIdTokenClaims();

      let builder = new AssociateQDIUserAccountBuilder()
        .withUserId(idToken!.sub)
        .withVerifiedDocTypeCode("BC")
        .withIssuingAuthority(formFields.get('state'))
        .withFamilyName(formFields.get('familyName'))
        .withBirthdate(formFields.get('dateOfBirth'));

      if (formFields.has('givenNames')) { builder.withGivenName(formFields.get('givenNames')); }

      if (formFields.has('certificateNumber') && formFields.get('certificateNumber').length !== 0) {
        builder.withVerifiedDocIdentifier("Certificate Number", getCertificateNumber(formFields));
      }

      if (formFields.has('registrationValue') && formFields.get('registrationValue').length !== 0) {
        builder.withVerifiedDocIdentifier(formFields.get('registrationType'), getRegistrationValue(formFields));
      }

      let certificate = new XBody(builder.build());

      await verifyBirthCertificate(certificate, token);
      setCounter(0);

      // NOTE : BC can only be used as a second or third (if required for DLA) doc
      
      // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
      // ipn_status not getting updated in token issue.
      const updatedSessionToken = updateSessionTokenIpn('ip2');
      saveGlobalState({ sessionToken: updatedSessionToken });

      //If this user is uplifting for the DL, record second doc so we
      //can remove it from the available list if third doc required
      if (dlaUser) dlaUser.secondDoc = "BC";
      
      navigate("/ip-uplift", { replace: true });
    }
    catch (error) {
      setCounter(counter + 1);
      if (error instanceof DocumentVerificationError) {
        // date of birth mismatch test
        if ("N" === error.failureCode && "Date of birth not match" === error.failureMessage) {
          setAlertVisibility(false, false, false, true);
        }
        else if ("D" === error.failureCode) {
          setAlertVisibility(false, true, false, false);
        }
        else if ("Document Temporarily Locked" === error.failureMessage) {
          navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }
        else if ("N" === error.failureCode && "Name not match" === error.failureMessage) {
          if (ipnLevel === "ip2") {
              // DLA user where name on verified doc doesnt match 1st and second doc
              navigate("/ip-uplift/names-not-match-hardstop", { replace: true });
          } else {
              // Setting localStorage.sessionToken.ipn = "ip1p_nc" as a temp fix for
              // ipn_status not getting updated in token issue.
              updateLocalStorageSessionTokenIpnAndStatus("ip1p_nc", "nc");
              navigate("/ip-uplift/name-change-options", { replace: true });
          }
        }
        else if ("N" === error.failureCode) {
          setAlertVisibility(true, false, false, false);
        }
        else {
          throw error;
        }
      }
      else if (error instanceof DocumentAlreadyVerifiedError) {
        setAlertVisibility(false, false, true, false);
      }
      else {
        throw error;
      }
    }
  }

  function setAlertVisibility(verifyFailed: boolean, docInvalid: boolean, docAlreadyVerified: boolean, dobMismtch: boolean) {
    setVerificationFailed(verifyFailed);
    setDocumentInvalid(docInvalid);
    setDocumentAlreadyVerified(docAlreadyVerified);
    setDobMismatch(dobMismtch);
  }

  function getCertificateNumber(formFields: any) {
    if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
      return +formFields.get('certificateNumber');
    } else {
      return formFields.get('certificateNumber');
    }
  }

  function getRegistrationValue(formFields: any) {
    if (statesRequireIdAsNumber.includes(formFields.get('state'))) {
      if (formFields.get('registrationType') === "Registration Date") {
        return formFields.get('registrationValue');
      } else {
        return +formFields.get('registrationValue');
      }
    } else {
      return formFields.get('registrationValue');
    }
  }

  function goBack() {
    navigate("/ip-uplift/verify-your-identity", { replace: true, state: {ipnLevel: ipnLevel, dlaUser} });
  }

  const [isFormValid, setIsFormValid] = useState(false);

  const options = new Map<string, any>();
  options.set("ACT", <VariLengthBirthCertificateFields state="ACT" setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
  options.set("NSW", <BirthCertificateFields state="NSW" certificateNumberMaxLength={11} registrationNumberMaxLength={7} givenNamesMandatory={false} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
  options.set("NT", <VariLengthBirthCertificateFields state="NT" dateFormatStrict={false} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
  options.set("QLD", <RegDateBirthCertificateFields state="QLD" givenNamesMandatory={true} setFormFields={setFormFields} setIsFormValid={setIsFormValid} certificateNumberLengthFixed={true} certificateNumberMaxLength={10} />);
  options.set("SA", <VariLengthBirthCertificateFields state="SA" setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
  options.set("TAS", <RegDateBirthCertificateFields state="TAS" validateLeadingZeros={true} certificateNumberMaxLength={11} certificateNumberLengthFixed={true} givenNamesMandatory={true} setFormFields={setFormFields} setIsFormValid={setIsFormValid} nameFieldsCharsAllowedPattern={/[^a-zA-Z0-9'‘’ \-().]/} nameFieldsCharsAllowedTrailingHint=" ' - ( ) ." allowNumbersForNames={true} />);
  options.set("VIC", <BirthCertificateFields state="VIC" givenNamesMandatory={false} familyNameMaxLength={80} givenNameMaxLength={80} certificateNumberMaxLength={12} registrationNumberMaxLength={10} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);
  options.set("WA", <BirthCertificateFields state="WA" givenNamesMandatory={false} nameFieldsCharsAllowedPattern={/[^a-zA-Z0-9'‘’ \-ÄÖÜäöü]/} nameFieldsCharsAllowedTrailingHint="' - Ä Ö Ü ä ö ü" certificateNumberMaxLength={11} certificateNumberLengthFixed={true} registrationNumberMaxLength={7} registrationNumberLengthFixed={true} setFormFields={setFormFields} setIsFormValid={setIsFormValid} />);

  return (
    <>
      {isAuthenticated &&
        <div>
          <div tabIndex={-1} ref={focusRef}></div>
          {/* <h1>Verify your second credential</h1> */}
          <h1>Verify your {ipnLevel === "ip1p" ? "second" : ""} credential</h1> 


          <div tabIndex={-1} ref={alertRef}>
            {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null}
            {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel="Australian Birth Certificate" /> : null}
            {documentInvalid ? <DocumentInvalidAlert /> : null}
            {dobMismatch ? isThirdDoc ? <ThirdDocDoBMismatchAlert /> : <DoBMismatchAlert /> : null}
          </div>
          <p>Enter your information, as it appears on your Australian Birth Certificate.</p>

          <section className="row cards-identity qg-cards">
            <Product heading="Australian Birth Certificate" media={require('../../assets/images/AustralianBirthCertificate.png')} action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />
          </section>

          <SelectForm id="state-of-issue" label="Australian Birth Certificate state of issue"
            inputValue={registrationState} isInputValid={registrationStateValid}
            setInputValue={setRegistrationState} setInputValid={setRegistrationStateValid}
            options={options}
            noSelectionText="Please select a state"
            validators={[mandatoryValidator]}
            infoMessage={<></>}
          />

          {registrationState !== '' &&
            <VerifyYourIdentityConsentAlert />
          }

          <div className="my-3">
            <ol className="questions">
              <li>
                <ul className='actions'>
                  <PrimaryButton id="submitButton" heading="Continue" action={verifyDocument} disabled={!isFormValid || registrationState === ''} />
                  <SecondaryButton id="backButton" heading="Back" action={goBack} />
                </ul>
              </li>
            </ol>
          </div>
        </div>
      }
    </>
  )
};

export default BirthCertificateForm;