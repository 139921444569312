import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import WarningAlert from "../components/swe/warning-alert";
import PrimaryButton from "../components/swe/primary-button";
import SecondaryButton from "../components/swe/secondary-button";
import useGlobalState from "../hooks/useGlobalState";
import RelyingPartyBuilder from "../utils/RelyingPartyBuilder";
import { TMP_DLA_URI } from "../constants";
import { Call, VERBS } from "../services/api-bridge";
import "../assets/css/qdi-sandbox.css";
import { useNavigate } from "react-router-dom";
import * as Constants from "../constants";

export const TermsAndConditions = () => {
  const [firstTCChecked, setFirstTCChecked] = useState(false);
  const [secondTCChecked, setSecondTCChecked] = useState(false);
  const [declineTCConfirm, setDeclineTCConfirm] = useState(false);
  const { globalState, saveGlobalState } = useGlobalState();

  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!isAuthenticated) {
          throw new Error("User is not authenticated!");
          // TODO : prevent authorised user re-displaying this page if already accepted latest Ts&Cs
        }
      } catch (error) {
        console.log('%c%s', 'color: #c00;', error);
        navigate("/unauthorised", { replace: true });
      }
    })();
  }, []);

  useEffect(() => {
    if ((firstTCChecked || secondTCChecked ) && declineTCConfirm) {
      setDeclineTCConfirm(false);
    }
  }, [firstTCChecked, secondTCChecked]);

  function declineTCPrompt() {
    if (declineTCConfirm) {
      navigate("/signup-paused", { replace: true });
    }
    setDeclineTCConfirm(true);
    if (firstTCChecked || secondTCChecked) {
      setFirstTCChecked(false);
      setSecondTCChecked(false);
    }
  }

  async function tAndcAccepted() {

    // Call X-API to set tandc_accepted timestamp
    const accesstoken = await getAccessTokenSilently({ ignoreCache: true });
    const idToken = await getIdTokenClaims();

    let body = {
      "DLAUserAccount": {
        "user_id": idToken.sub
      }
    }
    const returned = await Call('qdi/users/identity/tandc', VERBS.POST, body, accesstoken);
    if (200 == returned.code) {

      // Update our copy of the globalState.sessionToken.tanc_accepted value as we only get the
      // the value once when we log in and are given the session token
      //
      // Ideally, for a 200 response we should recieve the exact timestamp the API set
      // so we dont have to create our own which will obviously be a few millis out
      const userAcceptance = new Date().toISOString().split('.')[0] + '.000Z';
      let sessionTokenJSON = JSON.parse(localStorage.getItem('session_token'));
      sessionTokenJSON.tandc_accepted = userAcceptance;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
      saveGlobalState({ sessionToken: sessionTokenJSON });

      // Check if user has a verified email, if so this is a re-acceptence of the TsCs as opposed
      // to a new signup. If that is the case and CL2 was requested also trigger MFA. We now do
      // this here as we no longer require user to MFA before re-accepting TsCs
      if (user.email_verified === true && globalState.acr.toUpperCase().includes('CL2') ) {
        if (Constants.DEBUG) { console.log("TsCs re-accepted. User has verified email & CL2 so triiger MFA..."); }
        loginWithRedirect({
            acr_values: `urn:id.gov.au:tdif:acr:ip1:cl2`,
            redirectUri: `${window.location.origin}/callback-ip1`,
        });
        return;

      } else {
        if (Constants.DEBUG) { console.log("User %o just successfully accepted Ts&Cs on %o", idToken.sub, userAcceptance); }
      }

    } else {
      throw new Error("Invalid response.");
    }

    // Navigate back to uplift to continue uplift or MFA enrolment
    navigate("/ip-uplift", { replace: true });
  }

  return (
    <>
      {isAuthenticated && (
        <form className="qg-forms-v2">
          <h1 id="terms-and-conditions">Terms and Conditions</h1>

          {globalState.sessionToken?.tandc_accepted && (
            <WarningAlert heading="Terms and Conditions updated"
              message={<>
                <p>We have recently update the Terms and Conditions for the Queensland Digital Identity (QDI).</p>
                <p>Please accept them to continue using your QDI.</p>
              </>} />
          )}
          
          <p>The safety and security of your private information are our top priority. Your private information is protected under the law - to find out more, you can read our privacy notice.</p>

          <p>To continue you must agree to the <a target="_blank" rel="noopener" className="tmr-link" href="https://www.qld.gov.au/digital-identity/terms-of-use-and-privacy-notice/terms-and-conditions">Queensland Digital Identity Terms and Conditions.</a></p>


          <ol className="questions">
            <li>
              <fieldset>
                <legend>
                  <span className="label"></span>
                </legend>
                <ul className="choices qg-forms-v2__checkbox">
                  <li>
                    <input
                      type="checkbox"
                      id="t-and-c"
                      autoComplete="off"
                      checked={firstTCChecked}
                      onChange={(e) => setFirstTCChecked(e.target.checked)}
                    />
                    <label htmlFor="t-and-c">
                      <abbr className="required">*</abbr>
                      I have read and agree to the <a
                        target="_blank"
                        rel="noopener"
                        className="tmr-link"
                        href="https://www.qld.gov.au/digital-identity/terms-of-use-and-privacy-notice/terms-and-conditions">
                        Terms and Conditions</a>
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="other-t-and-c"
                      autoComplete="off"
                      checked={secondTCChecked}
                      onChange={(e) => setSecondTCChecked(e.target.checked)}
                    />
                    <label htmlFor="other-t-and-c">
                      <abbr className="required">*</abbr>
                      I have read and agree to the &nbsp;
                      <a
                        target="_blank"
                        rel="noopener"
                        className="tmr-link"
                        href="https://www.tmr.qld.gov.au/Help/Privacy">
                        Privacy Policy</a>, &nbsp;
                      <a
                        target="_blank"
                        rel="noopener"
                        className="tmr-link"
                        href="https://www.qld.gov.au/digital-identity/terms-of-use-and-privacy-notice/privacy-notice">
                        Privacy Notice
                      </a>, and the provision of my private information to be used for the
                      Queensland Digital Identity
                    </label>
                  </li>
                </ul>{(declineTCConfirm) && (
                  <WarningAlert 
                    heading="Declining Terms and Conditions" 
                    message={<>
                      <p>If you decline the Terms and Conditions you will not be able to create/secure your Queensland Digital Identity.</p>
                      <p>If you select 'Decline' again you will need to return to the agency, service or application that you were attempting to use to continue in the future.</p>
                    </>} 
                  />
                )}
              </fieldset>
            </li>
            <li className="footer">
              <ul className="actions">
                <PrimaryButton id='Accept' heading='Accept' action={tAndcAccepted} disabled={!firstTCChecked || !secondTCChecked} />
                <SecondaryButton id="Decline" heading="Decline" action={() => declineTCPrompt()} />
              </ul>
            </li>
          </ol>
        </form>
      )}

    </>
  );
};

export default TermsAndConditions;
