import React, { useState, useEffect } from "react";
import LabelDateFormat from "../../../swe/base/label-date-format";
import LabelInput from "../../../swe/base/label-input";
import ChangeOfNameCertificateType from "./change-of-name-certificate-type";
import { MandatoryValidator, LengthRangeValidator, EitherInputMandatoryValidator,
    NotFutureDateValidator, DateRangeValidator, MaximumLengthValidator,
    PatternValidator, NonSpaceInputValidator, NumbersNotAllowedValidator, MultipleSpacesInBetweenWordsValidator,
    NumbersOnlyValidator, DateOnOrAfterDateValidator, FixedLengthValidatorUnlessOtherInput
} from "../../../../utils/validation/validator";
import { CheckOtherDateOnOrAfterDateValidator } from "../../../../utils/validation/CheckOtherDateOnOrAfterDateValidator";
import LabelDateInput from "../../../swe/base/label-date-input";
import { LabelDateInputValidator } from "../../../../utils/validation/LabelDateInputValidator";

export const ChangeOfNameCertificateForm = (props: ChangeOfNameCertificateType) => {
    const [familyName, setFamilyName] = useState('');
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [givenNames, setGivenNames] = useState('');
    const [givenNamesValid, setGivenNamesValid] = useState(true);

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
    const [certificateNumber, setCertificateNumber] = useState('');
    const [certificateNumberValid, setCertificateNumberValid] = useState(true);    
    const [registrationValue, setRegistrationValue] = useState('');
    const [registrationValueValid, setRegistrationValueValid] = useState(true);
    const [registrationValueHint, setRegistrationValueHint] = useState('');
    
    const [regValueSelected, setRegValueSelected] = useState(false);
    const [certNoSelected, setCertNoSelected] = useState(false);

    let mandatoryValidator = new MandatoryValidator();
    let fnMandatoryValidator = new MandatoryValidator(props.familyNameMandatoryText);
    let familyNameRangeValidator = new LengthRangeValidator(1,props.familyNameMaxLength!);
    let givenNameRangeValidator = new LengthRangeValidator(1,props.givenNameMaxLength!);
    let certNmbrRequiredIfRegValueIsEmpty = new EitherInputMandatoryValidator(registrationValue, setRegistrationValueValid, `Certificate Number is required if you do not have a Registration ${props.registrationIsDate?'Date':'Number'}.`);
    let regValueRequiredIfCertNmbrIsEmpty = new EitherInputMandatoryValidator(certificateNumber, setCertificateNumberValid, `Registration ${props.registrationIsDate?'Date':'Number'} is required if you do not have a Certificate Number.`);
    let futureDateValidator = new NotFutureDateValidator();
    let patternValidatorForName = new PatternValidator(/[^a-zA-Z0-9'‘’ -]/, `The only special characters and symbols allowed are ' -`);
    let allSpacesValidator = new NonSpaceInputValidator();
    let numberNotAllowedValidator = new NumbersNotAllowedValidator();
    let multipleSpacesValidator = new MultipleSpacesInBetweenWordsValidator();
    let numbersOnlyValidator = new NumbersOnlyValidator();
    let regDateShouldBeOnOrAfterDateOfBirthValidator = new DateOnOrAfterDateValidator(dateOfBirth, "Date of Birth");
    let regDateCheckWhenDateOfBirthChangesValidator = new CheckOtherDateOnOrAfterDateValidator(registrationValue, setRegistrationValueValid, 'Registration Date must be on or after the Date of Birth', setRegistrationValueHint);
    let dateInputValidator = new LabelDateInputValidator();
    let certMaximumLengthValidator = new MaximumLengthValidator(props.certificateNumberMaxLength!, true);
    let regValueMaximumLengthValidator = new MaximumLengthValidator(props.registrationNumberMaxLength!,true);

    let certificateNumberLengthValidator = props.certificateNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.certificateNumberMaxLength!, true, registrationValue) : new MaximumLengthValidator(props.certificateNumberMaxLength!,true);
    let registrationNumberLengthValidator = props.registrationNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.registrationNumberMaxLength!, true, registrationValue) : new MaximumLengthValidator(props.registrationNumberMaxLength!,true);
    let givenNameValidators:any = [
        givenNameRangeValidator, 
        patternValidatorForName,
        allSpacesValidator,
        numberNotAllowedValidator,
        multipleSpacesValidator
    ];

    if (props.givenNamesMandatory) {
        if (props.givenNameMandatoryText === "") {
            givenNameValidators.push(mandatoryValidator);
        } else {
            let gnMandatoryValidator = new MandatoryValidator(props.givenNameMandatoryText);
            givenNameValidators.push(gnMandatoryValidator);
        }
    }

    let valids = [familyNameValid, givenNamesValid, dateOfBirthValid, certificateNumberValid, registrationValueValid];
    let mandatories = [props.state, familyName, dateOfBirth];
    if(props.givenNamesMandatory){        
        mandatories.push(givenNames);
    }

    useEffect(() => {
        const registrationType = `Registration ${props.registrationIsDate? "Date" : "Number"}`;
        let fields = new Map<string, string>();
        fields.set("state",props.state);
        fields.set("familyName",familyName.trim().replace(/[‘’]/g, "'"));
        fields.set("givenNames",givenNames.trim().replace(/[‘’]/g, "'"));
        fields.set("dateOfBirth",dateOfBirth);
        fields.set("certificateNumber",certificateNumber);
        fields.set("registrationValue",registrationValue);
        fields.set("registrationType",registrationType);

        props.setFormFields(fields);
        props.setIsFormValid(valids.every(v => v) && mandatories.every(m => m.length > 0) && (certificateNumber.length >0 || registrationValue.length>0));
    }, [...valids, ...mandatories, certificateNumber, registrationValue, props.state]);

    useEffect(() => { //Resets the form on state selection
        setFamilyName('');
        setFamilyNameValid(true);
        setGivenNames('');
        setGivenNamesValid(true);
        setDateOfBirth('');
        setDateOfBirthValid(true);
        setCertificateNumber('');
        setCertificateNumberValid(true);
        setRegistrationValue('');
        setRegistrationValueValid(true);
    }, [props.state]);

    function registrationValueSelected(checked: boolean) {
        setCertificateNumber('');
        setCertificateNumberValid(true);
        setRegValueSelected(checked);
        setCertNoSelected(!checked);
    }

    function certificateNumberSelected(checked: boolean) {
        setRegistrationValue('');
        setRegistrationValueValid(true);
        setCertNoSelected(checked);
        setRegValueSelected(!checked);
    }

    return (
        <>
            <form className="qg-forms-v2">
                <ol className="questions">
                    <legend><h3>Your details</h3></legend>
                    <li>
                        <ul className="questions">
                            <li>
                                <LabelInput 
                                    id="family-name"
                                    label="Family name (new)"
                                    inputValue={familyName}
                                    isInputValid={familyNameValid}
                                    setInputValue={setFamilyName}
                                    setInputValid={setFamilyNameValid}
                                    validators={[
                                        props.givenNameMandatoryText === "" ? mandatoryValidator : fnMandatoryValidator, 
                                        familyNameRangeValidator,
                                        patternValidatorForName,
                                        allSpacesValidator,
                                        numberNotAllowedValidator,
                                        multipleSpacesValidator
                                    ]}
                                    mandatory={true}
                                />
                            </li>
                            <li>
                                <LabelInput 
                                    id="given-names"
                                    label="Given name/s (new)"
                                    inputValue={givenNames}
                                    isInputValid={givenNamesValid}
                                    setInputValue={setGivenNames}
                                    setInputValid={setGivenNamesValid}
                                    validators={givenNameValidators}
                                    mandatory={props.givenNamesMandatory}
                                    persistentHint={!props.givenNamesMandatory && " Given name/s (new) must be entered if they appear on your document. Separate given names with a space."}
                                />
                            </li>
                            <li>
                                <LabelDateInput
                                    id="date-of-birth" 
                                    label="Date of birth" 
                                    inputValue={dateOfBirth}
                                    isInputValid={dateOfBirthValid}
                                    setInputValue={setDateOfBirth}
                                    setInputValid={setDateOfBirthValid}
                                    validators={[
                                        mandatoryValidator,
                                        dateInputValidator,
                                        futureDateValidator,
                                        regDateCheckWhenDateOfBirthChangesValidator
                                    ]}
                                />
                            </li>
                            <fieldset id="fset-change-of-name-cert">
                                <legend><h3>Certificate details</h3></legend>
                                <p><abbr className="required" title="required">*</abbr>You must provide at least one of the following:</p>                                
                                <div className="qg-forms-v2__radio">
                                    <li>
                                        <div className="choices">
                                            <input 
                                                type="radio" 
                                                name="certificate-details-options" 
                                                id="registration-value"
                                                checked={regValueSelected}
                                                onChange={(e) => registrationValueSelected(e.target.checked)}
                                            />
                                            <label htmlFor="registration-value">{props.registrationIsDate ? "Registration Date" : "Registration Number"}</label>
                                        </div>
                                        {regValueSelected && <div style={{paddingLeft: 48}}>                                       
                                            { props.registrationIsDate ?
                                                <LabelDateInput 
                                                    id="registration-value" 
                                                    label="Registration Date" 
                                                    inputValue={registrationValue}
                                                    isInputValid={registrationValueValid}
                                                    setInputValue={setRegistrationValue}
                                                    setInputValid={setRegistrationValueValid}
                                                    inputHint={registrationValueHint}
                                                    setInputHint={setRegistrationValueHint}
                                                    validators={[
                                                        regValueRequiredIfCertNmbrIsEmpty,
                                                        dateInputValidator,
                                                        futureDateValidator,
                                                        regDateShouldBeOnOrAfterDateOfBirthValidator
                                                    ]}
                                                    labelClassName="alt-required"
                                                    mandatory={false}
                                                    hideLabel={true}
                                                />
                                                :
                                                <LabelInput 
                                                    id="registration-value" 
                                                    label="Registration Number" 
                                                    inputValue={registrationValue}
                                                    isInputValid={registrationValueValid}
                                                    setInputValue={setRegistrationValue}
                                                    setInputValid={setRegistrationValueValid}
                                                    validators={[
                                                        regValueRequiredIfCertNmbrIsEmpty,
                                                        allSpacesValidator,
                                                        numbersOnlyValidator,
                                                        regValueMaximumLengthValidator, 
                                                        registrationNumberLengthValidator
                                                    ]}
                                                    persistentHint={<><a href="https://www.mygovid.gov.au/verify-a-change-in-name-change-of-name-certificate" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Registration Number?</a></>}
                                                    hintPosition="below"
                                                    labelClassName="alt-required"
                                                    mandatory={false}
                                                    hideLabel={true}
                                                />
                                            }
                                        </div>}  
                                    </li>
                                    <li>
                                        <div className="choices">
                                            <input 
                                                type="radio" 
                                                name="certificate-details-options" 
                                                id="certificate-number"
                                                checked={certNoSelected}
                                                onChange={(e) => certificateNumberSelected(e.target.checked)}
                                            />
                                            <label htmlFor="certificate-number">Certificate Number</label>
                                        </div>
                                        {certNoSelected && <div style={{paddingLeft: 48}}>                                                                             
                                            <LabelInput 
                                                id="certificate-number" 
                                                label="Certificate Number" 
                                                inputValue={certificateNumber}
                                                isInputValid={certificateNumberValid}
                                                setInputValue={setCertificateNumber}
                                                setInputValid={setCertificateNumberValid}
                                                validators={[
                                                    certNmbrRequiredIfRegValueIsEmpty,
                                                    allSpacesValidator,
                                                    numbersOnlyValidator,
                                                    certMaximumLengthValidator,
                                                    certificateNumberLengthValidator
                                                ]}
                                                persistentHint={<><a href="https://www.mygovid.gov.au/verify-a-change-in-name-change-of-name-certificate" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Certificate Number?</a></>}
                                                hintPosition="below"
                                                labelClassName="alt-required"
                                                mandatory={false}
                                                hideLabel={true}
                                            />
                                        </div>}
                                    </li>
                                </div>
                            </fieldset>
                        </ul>
                    </li>
                </ol>
            </form>
        </>
    );
}

ChangeOfNameCertificateForm.defaultProps = {
    familyNameMaxLength: 50,
    familyNameMandatoryText: "",
    givenNameMaxLength: 60,
    givenNamesMandatory: true,
    givenNameMandatoryText: "",
    registrationIsDate: false,
    certificateNumberMaxLength: 9,
    certificateNumberLengthFixed: false,
    registrationNumberMaxLength: 10,
    registrationNumberLengthFixed: false,
}

export default ChangeOfNameCertificateForm;