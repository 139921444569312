import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useGlobalState from "../../../hooks/useGlobalState";
import InfoAlert from "../../swe/info-alert";
import LabelInput from "../../swe/base/label-input";
import DocumentVerificationFailedAlert from "../alerts/document-verification-failed-alert";
import DocumentAlreadyVerifiedAlert from "../alerts/document-already-verified-alert";
import DocumentInvalidAlert from "../alerts/document-invalid-alert"
import PrimaryButton from "../../swe/primary-button";
import SecondaryButton from "../../swe/secondary-button";
import { DocumentVerificationError, DocumentAlreadyVerifiedError, XBody, AssociateQDIUserAccountBuilder } from "../../../services/x-api";
import { MandatoryValidator, MaximumLengthValidator, PatternValidator, AustralianPassportValidator, 
         NonSpaceInputValidator, DeactivatableMandatoryValidator, NumbersNotAllowedValidator, NotFutureDateValidator } from "../../../utils/validation/validator";
import { FlexibleDateValidator } from "../../../utils/validation/FlexibleDateValidator";
import FormTypeProps from "./Form-type";
import MFAHandler from "../../../utils/MFAHandler";
import * as Constants from '../../../constants';
import { formatDateString, updateLocalStorageSessionTokenIpnAndStatus, updateSessionTokenIpn } from '../../../utils/helpers';
import VerifyYourIdentityConsentAlert from "../alerts/verifying-identity-consent-alert";
import DoBMismatchAlert from "../alerts/dob-mismatch-alert";
import LabelDateInput from "../../swe/base/label-date-input";
import ThirdDocDoBMismatchAlert from "../alerts/third-doc-dob-mismatch-alert copy";

interface TravelDocumentFormTypeProps extends FormTypeProps {
    documentLengthValidatorLength?: number,
    nameLengthValidatorLength?: number,
    namePatterValidatorPattern?: RegExp,
    namePatternValidatorPatternHint?: string,
    givenNameMandatory?: boolean, // can add per field to toggle
    primaryCredentialLabel?: string
    allowSingleName?: boolean
}

function TravelDocumentForm({ instructions, product, backNavigateTo, nextNavigateTo, typeCode, action, ipnLevel, dlaUser,
    documentLengthValidatorLength = 9, nameLengthValidatorLength = 31, namePatterValidatorPattern = /[^a-zA-Z0-9'‘’\- .]/,
    namePatternValidatorPatternHint = "Only the following special characters are allowed ' -.",
    givenNameMandatory = false, primaryCredentialLabel = "card", allowSingleName = false, identifierType }: TravelDocumentFormTypeProps) {
    const navigate = useNavigate();
    const { globalState, saveGlobalState } = useGlobalState();
    const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    if (Constants.DEBUG) { console.log('%cisAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;', isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState); }

    let isThirdDocVisaForm = ((ipnLevel === "ip2" || ipnLevel === "IP2") && dlaUser && typeCode === "VI") ? true : false;

    // validators
    let mandatoryValidator = new MandatoryValidator();
    let documentLengthValidator = (documentLengthValidatorLength != 9) ?
        new MaximumLengthValidator(documentLengthValidatorLength) :
        new AustralianPassportValidator();
    let nameLengthValidator = new MaximumLengthValidator(nameLengthValidatorLength);
    let namePatternValidator = new PatternValidator(namePatterValidatorPattern, namePatternValidatorPatternHint);
    let flexibleDateValidator = new FlexibleDateValidator();
    let allSpacesValidator = new NonSpaceInputValidator();
    let numbersNotAllowedValidator = new NumbersNotAllowedValidator();
    let givenNameMandatoryValidator = new DeactivatableMandatoryValidator(givenNameMandatory);
    let futureDateValidator = new NotFutureDateValidator();
    // state
    const [travelDocumentNumber, setTravelDocumentNumber] = useState('');
    const [travelDocumentNumberValid, setTravelDocumentNumberValid] = useState(true);
    const [familyName, setFamilyName] = useState('');
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [givenNames, setGivenNames] = useState('');
    const [givenNamesValid, setGivenNamesValid] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
    const [isSingleName, setIsSingleName] = useState(false);

    const [isContinueDisabled, setIsContinueDisabled] = useState(true);
    const validateIsContinueDisabled = () => {
        let areAllFieldsValid = (travelDocumentNumberValid && familyNameValid && (allowSingleName || givenNamesValid) && dateOfBirthValid);
        let areAllMandatoryFieldsPopulated = (travelDocumentNumber.length > 0 && familyName.length > 0 && dateOfBirth.length > 0);
        if(givenNameMandatory){
            areAllMandatoryFieldsPopulated = (travelDocumentNumber.length > 0 && familyName.length > 0 && givenNames.length > 0 && dateOfBirth.length > 0);
        }
        return (!areAllFieldsValid || !areAllMandatoryFieldsPopulated);
    }

    function navigateBackTo() {
        //const dlaUser = globalState.dlaUser;
        navigate(backNavigateTo, { replace: true, state: { ipnLevel: ipnLevel, dlaUser } });
    }

    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const [dobMismatch, setDobMismatch] = useState(false);
    const [counter, setCounter] = useState(0);
    const alertRef = useRef(null);

    const verifyTravelDocument = async () => {
        console.group('async');
        setAlertVisibility(false, false, false, false); //Reset alert visibility
        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }

        try {
            const token = await getAccessTokenSilently({ scope: "update:add_identity_proofing_doc" });
            const idToken = await getIdTokenClaims();
            if (Constants.DEBUG) { console.log(token, idToken); }

            let builder = new AssociateQDIUserAccountBuilder()
                .withUserId(idToken!.sub)
                .withBirthdate(formatDateString(dateOfBirth))
                .withFamilyName(familyName.trim().replace(/[‘’]/g, "'"))
                .withGivenName(givenNames.trim().replace(/[‘’]/g, "'"))
                .withVerifiedDocTypeCode(typeCode)
                .withVerifiedDocIdentifier(identifierType, travelDocumentNumber.trim());
            let envelope = new XBody(builder.build());

            await action(envelope, token);
            setCounter(0);

            if (Constants.DEBUG) {
                console.log('%cisAuthenticated: %o; user: %o\nipn:%o\nacr:%o\nstate:%o\nglobalState:%o', 'color: #9c6;', isAuthenticated, user, globalState.sessionToken?.ipn, globalState.sessionToken?.acr_values, globalState.idState, globalState);
                console.log('%ctoken:%o\nidToken:%o\nenvelope:%o', 'color: #6f3;', token, idToken, envelope);
            }

            if(ipnLevel === 'ip1p'){
                // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
                // ipn_status not getting updated in token issue.
                const updatedSessionToken = updateSessionTokenIpn('ip2');
                saveGlobalState({ sessionToken: updatedSessionToken });

                //If this user is uplifting for the DL, record second doc so we
                //can remove it from the available list if third doc required
                //const dlaUser = globalState.dlaUser;
                if (dlaUser) dlaUser.secondDoc = typeCode;                
            }

            navigate("/ip-uplift", { replace: true });
        }
        catch (error) {
            if (Constants.DEBUG) { console.log(error); }
            setCounter(counter + 1);
            if (error instanceof DocumentVerificationError) {
                // date of birth mismatch test
                if ("N" == error.failureCode && "Date of birth not match" == error.failureMessage) {
                    setAlertVisibility(false, false, false, true);
                }
                else if ("N" == error.failureCode && "Name not match" == error.failureMessage) {
                    if (ipnLevel === "ip2") {
                        // DLA user where name on verified doc doesnt match 1st and second doc
                        navigate("/ip-uplift/names-not-match-hardstop", { replace: true });
                    } else {
                        // Setting localStorage.sessionToken.ipn = "ip1p_nc" as a temp fix for
                        // ipn_status not getting updated in token issue.
                        updateLocalStorageSessionTokenIpnAndStatus("ip1p_nc", "nc");
                        navigate("/ip-uplift/name-change-options", { replace: true });
                    }
                }
                else if ("D" == error.failureCode && "Invalid document used in EOI verification" == error.failureMessage) {
                    setAlertVisibility(false, true, false, false);
                }
                else if ("Document Temporarily Locked" == error.failureMessage) {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                }
                else {
                    setAlertVisibility(true, false, false, false);
                }
            } else if (error instanceof DocumentAlreadyVerifiedError) {
                setAlertVisibility(false, false, true, false);
            } else {
                throw error;
            }
        }
        console.groupEnd();
    }

    function setAlertVisibility(verifyFailed: boolean, docInvalid: boolean, docAlreadyVerified: boolean, dobMismtch: boolean){    
        setVerificationFailed(verifyFailed);
        setDocumentInvalid(docInvalid);
        setDocumentAlreadyVerified(docAlreadyVerified);
        setDobMismatch(dobMismtch);
    }

    useEffect(()=> {
        if(verificationFailed || documentAlreadyVerified || documentInvalid || dobMismatch){
            alertRef?.current?.focus();
        }
    }, [verificationFailed,documentAlreadyVerified,documentInvalid,dobMismatch]);

    useEffect(() => {
        const isInvalid = validateIsContinueDisabled();
        setIsContinueDisabled(isInvalid);

        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }

    }, [counter, travelDocumentNumberValid, familyNameValid, givenNamesValid, dateOfBirthValid, travelDocumentNumber, familyName, givenNames, dateOfBirth]);

    const onSingleNameCheckBoxChange = (checked: boolean) => {
        setFamilyNameValid(true);
        setGivenNamesValid(true);
        setIsSingleName(checked);
        setFamilyName('');
        if(checked) {
            setGivenNames('-');
        } else {
            setGivenNames('');
        }
    }

    return (
        <>
            <div tabIndex={-1} ref={alertRef}>
                {verificationFailed ? <DocumentVerificationFailedAlert primaryCredentialLabel={primaryCredentialLabel}/> : null}
                {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null}
                {documentInvalid ? <DocumentInvalidAlert /> : null}
                {dobMismatch ? isThirdDocVisaForm ? <ThirdDocDoBMismatchAlert /> : <DoBMismatchAlert /> : null}
            </div>
            {instructions}

            <section className="row cards-identity qg-cards cards__equal-height">
                {product}
            </section>

            <form className="qg-forms-v2">
                <ul className="questions">
                    <li>
                        <LabelInput
                            id="travel-document-number"
                            label="Travel Document Number"
                            inputValue={travelDocumentNumber}
                            isInputValid={travelDocumentNumberValid}
                            setInputValue={setTravelDocumentNumber}
                            setInputValid={setTravelDocumentNumberValid}
                            validators={[mandatoryValidator, allSpacesValidator, documentLengthValidator]}
                        />
                    </li>
                    {allowSingleName &&
                    <>
                        <li className="no-top-bottom-padding">
                            <legend><h3>Your details</h3></legend>
                        </li>
                        <li className="no-top-bottom-padding">
                            <ul className="choices qg-forms-v2__checkbox">
                                <li>
                                    <input 
                                        type="checkbox" 
                                        id="checked-details" 
                                        autoComplete="off" 
                                        area-checked="false"
                                        checked={isSingleName}
                                        onChange={(e)=> onSingleNameCheckBoxChange(e.target.checked)}
                                    />
                                    <label htmlFor="checked-details">
                                        <span>
                                            <p>I only have a single name</p>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </li>
                    </>}
                    {allowSingleName && isSingleName ? 
                    <>
                        <li className="no-top-bottom-padding">
                            <small className="hint">Please enter your name into the Name field</small>
                        </li>
                        <li>
                            <LabelInput
                                id="name"
                                label="Name"
                                inputValue={familyName}
                                isInputValid={familyNameValid}
                                setInputValue={setFamilyName}
                                setInputValid={setFamilyNameValid}
                                validators={[mandatoryValidator, allSpacesValidator, nameLengthValidator, namePatternValidator, numbersNotAllowedValidator]}
                            />
                        </li>
                    </> :
                    <>
                        <li>
                            <LabelInput
                                id="family-name"
                                label="Family name"
                                inputValue={familyName}
                                isInputValid={familyNameValid}
                                setInputValue={setFamilyName}
                                setInputValid={setFamilyNameValid}
                                validators={[mandatoryValidator, allSpacesValidator, nameLengthValidator, namePatternValidator, numbersNotAllowedValidator]}
                            />
                        </li> 
                        <li>
                            <LabelInput
                                id="given-names"
                                label="Given name/s"
                                persistentHint={!givenNameMandatory && " Given name/s must be entered if they appear on your document. Separate given names with a space."}
                                inputValue={givenNames}
                                isInputValid={givenNamesValid}
                                setInputValue={setGivenNames}
                                setInputValid={setGivenNamesValid}
                                validators={[nameLengthValidator, allSpacesValidator, namePatternValidator, givenNameMandatoryValidator, numbersNotAllowedValidator]}
                                mandatory={givenNameMandatory}
                            />
                        </li>
                    </>}
                    <li>
                        <LabelDateInput
                            id="date-of-birth"
                            label="Date of birth"
                            inputValue={dateOfBirth}
                            isInputValid={dateOfBirthValid}
                            setInputValue={setDateOfBirth}
                            setInputValid={setDateOfBirthValid}
                            validators={[mandatoryValidator, flexibleDateValidator, futureDateValidator]}
                            flexibleDate={true}
                        />
                    </li>
                </ul>
            </form>

            <VerifyYourIdentityConsentAlert />

            <div className="my-3">
                <ol className="questions">
                    <li>
                        <ul className='actions'>
                            <PrimaryButton id="submitButton" heading="Continue" action={verifyTravelDocument} disabled={isContinueDisabled} />
                            <SecondaryButton id="backButton" heading="Back" action={navigateBackTo} />
                        </ul>
                    </li>
                </ol>
            </div>
        </>
    )
};

export default TravelDocumentForm;