import React from 'react';
import Product from './product';
import { useNavigate } from 'react-router-dom';
import { DLAUser } from '../../interfaces/DLAUser';

/**
 * TODO
 * 
 * @param docList List of documents with docType and heading to display
 * @param ipnLevel 
 * @returns 
 */
type props= {
    docList?: any[],
    ipnLevel?: string,
    dlaUser?: DLAUser,
}

export function ProductList ({docList=[], ipnLevel="", dlaUser} : props) {
    
    const dlImageURL = require('../../assets/images/driverlicence.jpg');
    const mlImageURL = require('../../assets/images/marine.jpg');
    const picImageURL = require('../../assets/images/photoidentification.png');
    const ppImageURL = require('../../assets/images/Australian Passport.png');
    const isdlImageURL = require('../../assets/images/Interstate DL.png');
    const viImageURL = require('../../assets/images/Australian Visa.png');
    const ccImageURL = require('../../assets/images/Australian Citizenship Certificate or Citizenship of Descent.png');
    const imImageURL = require('../../assets/images/Immigration Status or Australian Migration Status.png');
    const mdImageURL = require('../../assets/images/AustralianMedicareCards.png');
    const bcImageURL = require('../../assets/images/AustralianBirthCertificate.png');
    
    const navigate = useNavigate();
    

    const displayDriversLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/drivers-licence-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser }});
    }
    const displayMarineLicenceForm = () => {
        navigate("/ip-uplift/verify-your-identity/marine-licence-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser }});
    }
    const displayPhotoIdentificationForm = () => {
        navigate("/ip-uplift/verify-your-identity/photo-id-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser }});
    }
    const displayPassportForm = () => {
        navigate("/ip-uplift/verify-your-identity/passport-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser }});
    }
    const displayInterstateLicenceSelectionPage = () => {
        navigate("/ip-uplift/verify-your-identity/interstate-dl-selection", { replace: true, state: { ipnLevel: ipnLevel }});
    }

    const displayVisaForm = () => {
        navigate("/ip-uplift/verify-your-identity/visa-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser }});
    }

    const displayCitizenshipForm = () => {
        navigate("/ip-uplift/verify-your-identity/citizenship-certificate-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser } });
    }

    const displayImmiCardForm = () => {
        navigate("/ip-uplift/verify-your-identity/immicard-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser } });
    }

    const displayBirthCertificateForm = () => {
        navigate("/ip-uplift/verify-your-identity/birth-certificate-form", { replace: true, state: { ipnLevel: ipnLevel, dlaUser } });
    }

    const displayMedicareForm = () => {
        navigate("/ip-uplift/verify-your-identity/medicare-card-type", { replace: true, state: { ipnLevel: ipnLevel } });
    }

    const displayDefault = () => {
        navigate("/ip-uplift", { replace: true });
    }

    const getHeading = (docType: string) => {
        switch(docType) {
            case "DL":
                return "Queensland Driver Licence";
            case "PIC":
                return "Queensland Photo Identification Card or Adult Proof of Age Card";
            case "ML":
                return "Queensland Recreational Marine Licence";
            case "PP":
                return "Australian Passport";
            case "ISDL":
                return "Australian Issued Driver Licence (excluding Queensland)";
            case "VI":
                return "Foreign passport with valid visa or entry stamp";
            case "CC":
                return "Australian Citizenship Certificate or Australian Citizenship of Descent";
            case "IM":
                return "ImmiCard - Evidence of Immigration Status or Australian Migration Status";
            case "BC":
                return "Australian Birth Certificate";
            case "MD":
                return "Australian Medicare card";
            default:
                return "";
        }
    }

    const getThumbnailImage = (docType: string) => {
        switch(docType) {
            case "DL":
                return dlImageURL;
            case "ML":
                return mlImageURL;
            case "PIC":
                return picImageURL;
            case "PP":
                return ppImageURL;
            case "ISDL":
                return isdlImageURL;
            case "VI":
                return viImageURL;
            case "CC":
                return ccImageURL;
            case "IM":
                return imImageURL;
            case "MD":
                return mdImageURL;
            case "BC":
                return bcImageURL;
            default:
                return null;
        }
    }

    const getAction = (docType: string) => {
        switch(docType) {
            case "DL":
                return displayDriversLicenceForm;
            case "ML":
                return displayMarineLicenceForm;
            case "PIC":
                return displayPhotoIdentificationForm;
            case "PP":
                return displayPassportForm;
            case "ISDL":
                return displayInterstateLicenceSelectionPage;
            case "VI":
                return displayVisaForm;
            case "CC":
                return displayCitizenshipForm;
            case "IM":
                return displayImmiCardForm;
            case "MD":
                return displayMedicareForm;
            case "BC":
                return displayBirthCertificateForm;
            default:
                return displayDefault;
        }
    }
    
    return (
        <>
            {docList.map((docType, index) => {
                return <Product
                            heading={getHeading(docType)} 
                            media={getThumbnailImage(docType)}
                            action={getAction(docType)}
                            key={index}
                        />
            })}
        </>
    );
}

export default ProductList;