import { useState, useEffect } from "react";
import LabelInput from "../../../swe/base/label-input";
import { MandatoryValidator, LengthRangeValidator, PatternValidator, DateRangeValidator, NotFutureDateValidator,
    MaximumLengthValidatorUnlessOtherInput, NumbersOnlyValidator, EitherInputMandatoryValidator,
    DateOnOrAfterDateValidator, IsValidDateValidator, FixedLengthValidatorUnlessOtherInput, NonSpaceInputValidator,
    LeadingZeroValidator, MaximumLengthValidator, NumbersNotAllowedValidator
} from "../../../../utils/validation/validator";
import { FlexibleDateValidator } from "../../../../utils/validation/FlexibleDateValidator";
import { CheckOtherDateOnOrAfterDateValidator } from "../../../../utils/validation/CheckOtherDateOnOrAfterDateValidator";
import BirthCertificateType from "./birth-certificate-type";
import * as Util from '../../../../utils/helpers';
import LabelDateInput from "../../../swe/base/label-date-input";
import { LabelDateInputValidator } from "../../../../utils/validation/LabelDateInputValidator";


export const BirthCertificateFields = (props : BirthCertificateType) => {

    const [certificateNumber, setCertificateNumber] = useState('');
    const [certificateNumberValid, setCertificateNumberValid] = useState(true);

    const [familyName, setFamilyName] = useState('');
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [givenNames, setGivenNames] = useState('');
    const [givenNamesValid, setGivenNamesValid] = useState(true);

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);

    const [registrationValue, setRegistrationValue] = useState('');
    const [registrationValueValid, setRegistrationValueValid] = useState(true);
    const [registrationValueHint, setRegistrationValueHint] = useState('');

    const [regValueSelected, setRegValueSelected] = useState(false);
    const [certNoSelected, setCertNoSelected] = useState(false);

    let mandatoryValidator = new MandatoryValidator();
    let familyNameRangeValidator = new LengthRangeValidator(1,props.familyNameMaxLength!);
    let givenNameRangeValidator = new LengthRangeValidator(1,props.givenNameMaxLength!);
    let patternValidatorForName = new PatternValidator(props.nameFieldsCharsAllowedPattern!, `The only special characters and symbols allowed are ${props.nameFieldsCharsAllowedTrailingHint}`);
    let dateRangeValidator = new DateRangeValidator(1900, new Date().getFullYear());
    let futureDateValidator = new NotFutureDateValidator();
    let dateInputValidator = new LabelDateInputValidator();
    let flexibleDateValidator = new FlexibleDateValidator();
    let certificateNumberLengthValidator = props.certificateNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.certificateNumberMaxLength!, true, registrationValue) : new MaximumLengthValidatorUnlessOtherInput(props.certificateNumberMaxLength!,true, registrationValue);
    let registrationValueLengthValidator = props.registrationNumberLengthFixed? new FixedLengthValidatorUnlessOtherInput(props.registrationNumberMaxLength!, true, certificateNumber) : new MaximumLengthValidatorUnlessOtherInput(props.registrationNumberMaxLength!,true, certificateNumber);
    let numbersOnlyValidator = new NumbersOnlyValidator();
    let allSpacesValidator = new NonSpaceInputValidator();
    let mustHaveCertificateNumberIfRegistrationDateEmptyValidator = new EitherInputMandatoryValidator(registrationValue, setRegistrationValueValid, `Certificate Number is required if you do not have a Registration ${props.registrationIsDate?'Date':'Number'}.`);
    let mustHaveRegistrationValueIfCertificateNumberEmptyValidator =  new EitherInputMandatoryValidator(certificateNumber, setCertificateNumberValid, `Registration ${props.registrationIsDate?'Date':'Number'} is required if you do not have a Certificate Number.`);
    let registrationDateMustBeOnOrAfterDateOfBirthValidator = new DateOnOrAfterDateValidator(dateOfBirth, "Date of birth");
    let registrationDateCheckWhenDateOfBirthChangesValidator = new CheckOtherDateOnOrAfterDateValidator(registrationValue, setRegistrationValueValid, 'Registration Date must be on or after the Date of birth', setRegistrationValueHint);
    let leadingZeroValidator = new LeadingZeroValidator(props.validateLeadingZeros!);
    let certMaximumLengthValidator = new MaximumLengthValidator(props.certificateNumberMaxLength!, true);
    let regNumberMaximumLengthValidator = new MaximumLengthValidator(props.registrationNumberMaxLength!, true);
    let numbersNotAllowedValidator = new NumbersNotAllowedValidator();
    let familyNameValidators:any = [mandatoryValidator, familyNameRangeValidator, allSpacesValidator, patternValidatorForName];
    let givenNameValidators:any = [givenNameRangeValidator, allSpacesValidator, patternValidatorForName];
    let mandatories = [props.state, familyName, dateOfBirth];
    if(props.givenNamesMandatory){
        mandatories.push(givenNames);
        givenNameValidators.push(mandatoryValidator);
    }

    if(!props.allowNumbersForNames){
        familyNameValidators.push(numbersNotAllowedValidator);
        givenNameValidators.push(numbersNotAllowedValidator);
    }

    let dobValidators:any = [mandatoryValidator];
    if (props.dateFormatStrict === false) {            
        dobValidators.push(flexibleDateValidator);
    }
    else { 
        dobValidators.push(dateInputValidator); 
    }
    dobValidators.push(futureDateValidator,registrationDateCheckWhenDateOfBirthChangesValidator);

    let valids = [certificateNumberValid, familyNameValid, givenNamesValid, dateOfBirth, registrationValueValid];

    if (typeof certificateNumber === "string") { certificateNumber.trim(); }
    if (typeof registrationValue === "string") { registrationValue.trim(); }

    useEffect(() => {
        const registrationType = `Registration ${props.registrationIsDate? "Date" : "Number"}`;
        let fields = new Map<string, string>();
        fields.set("state",props.state);
        fields.set("familyName",familyName.trim().replace(/[‘’]/g, "'"));
        fields.set("givenNames",givenNames.trim().replace(/[‘’]/g, "'"));
        fields.set("dateOfBirth",props.dateFormatStrict===true? dateOfBirth : Util.formatDateString(dateOfBirth));
        fields.set("certificateNumber",certificateNumber);
        fields.set("registrationValue",registrationValue);
        fields.set("registrationType",registrationType);
        
        props.setFormFields(fields);
        props.setIsFormValid(valids.every(v => v) && mandatories.every(m => m.length > 0) && (certificateNumber.length >0 || registrationValue.length>0));
    }, [...valids, ...mandatories, certificateNumber, registrationValue]);

    useEffect(() => {
        setFamilyName('');
        setFamilyNameValid(true);
        setGivenNames('');
        setGivenNamesValid(true);    
        setDateOfBirth('');
        setDateOfBirthValid(true);
        setRegistrationValue('');
        setRegistrationValueValid(true);
        setRegistrationValueHint('');
    }, [props.state]);


    function registrationValueSelected(checked: boolean) {
        setCertificateNumber('');
        setCertificateNumberValid(true);
        setRegValueSelected(checked);
        setCertNoSelected(!checked);
    }

    function certificateNumberSelected(checked: boolean) {
        setRegistrationValue('');
        setRegistrationValueValid(true);
        setCertNoSelected(checked);
        setRegValueSelected(!checked);
    }

    return (
        <>
            <form className="qg-forms-v2">
                <ol className="questions">
                    <legend><h3>Your details</h3></legend>
                    <li>
                        <LabelInput 
                            id="family-name" 
                            label="Family name" 
                            inputValue={familyName}
                            isInputValid={familyNameValid}
                            setInputValue={setFamilyName}
                            setInputValid={setFamilyNameValid}
                            validators={familyNameValidators}
                            mandatory={true}
                        />
                    </li>
                    <li>
                        <LabelInput 
                            id="given-names" 
                            label="Given name/s" 
                            persistentHint={!props.givenNamesMandatory && " Given name/s must be entered if they appear on your document. Separate given names with a space."}
                            inputValue={givenNames}
                            isInputValid={givenNamesValid}
                            setInputValue={setGivenNames}
                            setInputValid={setGivenNamesValid}
                            validators={givenNameValidators}
                            mandatory={props.givenNamesMandatory}
                        />
                    </li>
                    <li>
                        <LabelDateInput
                            id="date-of-birth" 
                            label="Date of birth" 
                            inputValue={dateOfBirth}
                            isInputValid={dateOfBirthValid}
                            setInputValue={setDateOfBirth}
                            setInputValid={setDateOfBirthValid}
                            validators={dobValidators}
                            flexibleDate={!props.dateFormatStrict}
                        />
                    </li>
                    <li>
                        <fieldset id="fset-birth-certificate">
                            <legend><h3>Certificate details</h3></legend>
                            <p><abbr className="required" title="required">*</abbr>You must provide one of the following:</p>
                            <div className="qg-forms-v2__radio">
                                <li>
                                    <div className="choices">
                                        <input 
                                            type="radio" 
                                            name="certificate-details-options" 
                                            id="registration-value"
                                            checked={regValueSelected}
                                            onChange={(e) => registrationValueSelected(e.target.checked)}
                                        />
                                        <label htmlFor="registration-value">{props.registrationIsDate ? "Registration Date" : "Registration Number"}</label>
                                    </div>
                                    {regValueSelected && <div style={{paddingLeft: 48}}>                                       
                                        { props.registrationIsDate ?
                                            <LabelDateInput
                                                id="registration-value" 
                                                label="Registration Date" 
                                                inputValue={registrationValue}
                                                inputHint={registrationValueHint}
                                                isInputValid={registrationValueValid}
                                                setInputValue={setRegistrationValue}
                                                setInputValid={setRegistrationValueValid}
                                                setInputHint={setRegistrationValueHint}
                                                validators={[
                                                    mustHaveRegistrationValueIfCertificateNumberEmptyValidator,
                                                    dateInputValidator,
                                                    registrationDateMustBeOnOrAfterDateOfBirthValidator,
                                                    futureDateValidator
                                                ]}
                                                labelClassName="alt-required"
                                                mandatory={false}
                                                hideLabel={true}
                                            />
                                            :
                                            <LabelInput 
                                                id="registration-value" 
                                                label="Registration Number" 
                                                inputValue={registrationValue}
                                                isInputValid={registrationValueValid}
                                                setInputValue={setRegistrationValue}
                                                setInputValid={setRegistrationValueValid}
                                                validators={[
                                                    mustHaveRegistrationValueIfCertificateNumberEmptyValidator,
                                                    allSpacesValidator,
                                                    numbersOnlyValidator,
                                                    regNumberMaximumLengthValidator,
                                                    registrationValueLengthValidator,
                                                ]}
                                                persistentHint={<><a href="https://www.mygovid.gov.au/verifying-your-birth-certificate#NSW,%20QLD,%20TAS,%20VIC%20and%20WA" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Registration Number?</a></>}
                                                hintPosition="below"
                                                labelClassName="alt-required"
                                                mandatory={false}
                                                hideLabel={true}
                                            />
                                        }
                                    </div>}  
                                </li>
                                <li>
                                    <div className="choices">
                                        <input 
                                            type="radio" 
                                            name="certificate-details-options" 
                                            id="certificate-number"
                                            checked={certNoSelected}
                                            onChange={(e) => certificateNumberSelected(e.target.checked)}
                                        />
                                        <label htmlFor="certificate-number">Certificate Number</label>
                                    </div>
                                    {certNoSelected && <div style={{paddingLeft: 48}}>                                                                             
                                        <LabelInput 
                                            id="certificate-number" 
                                            label="Certificate Number" 
                                            inputValue={certificateNumber}
                                            isInputValid={certificateNumberValid}
                                            setInputValue={setCertificateNumber}
                                            setInputValid={setCertificateNumberValid}
                                            validators={[
                                                mustHaveCertificateNumberIfRegistrationDateEmptyValidator,
                                                leadingZeroValidator,
                                                allSpacesValidator,
                                                numbersOnlyValidator, 
                                                certMaximumLengthValidator,
                                                certificateNumberLengthValidator, 
                                            ]}
                                            persistentHint={<><a href="https://www.mygovid.gov.au/verifying-your-birth-certificate#NSW,%20QLD,%20TAS,%20VIC%20and%20WA" target="_blank" className="tmr-link" rel="noopener noreferrer">Where is the Certificate Number?</a></>}
                                            hintPosition="below"
                                            labelClassName="alt-required"
                                            mandatory={false}
                                            hideLabel={true}
                                        />
                                    </div>}
                                </li>
                            </div>
                        </fieldset>
                    </li>
                </ol>
            </form>
        </>
    )
}

BirthCertificateFields.defaultProps = {
    givenNamesMandatory : true,
    familyNameMaxLength : 50,
    givenNameMaxLength : 60,
    nameFieldsCharsAllowedPattern: /[^a-zA-Z0-9'‘’ \-]/,
    nameFieldsCharsAllowedTrailingHint: " ' -",
    dateFormatStrict: true,
    certificateNumberMaxLength: 10,
    certificateNumberLengthFixed: false,
    registrationNumberMaxLength: 10,
    registrationNumberLengthFixed: false,
    registrationIsDate: false,
    validateLeadingZeros: false,
    allowNumbersForNames: false,
}

export default BirthCertificateFields;