import { act } from 'react-dom/test-utils';
import * as Constants from '../constants';

/**
  * Convert yyyy-mm-dd or yyyy-mm- or yyyy-- to respectively yyyy-mm-dd or yyyy-mm or yyyy format.
  * 
  * @param ddmmyyyy 
  */
export const formatDateString = (yyyymmdd: string): string => {
  //return ddmmyyyy.split("/").reverse().join("-");
  let dateParts = yyyymmdd.split('-');
  let year = +dateParts[0];
  let month = +dateParts[1];
  let day = +dateParts[2];

  if(year > 0 && month > 0 && day === 0) {
    return dateParts[0] + '-' + dateParts[1];
  } else if (year > 0 && month === 0 && day === 0) {
    return dateParts[0];
  }

  return yyyymmdd;
}

export const extractIpn = (str: string) => {
  if (!str) return null;
  const parts = str.split(':');
  const ipn = parts[4];
  return ipn;
};

export const extractCl = (str: string) => {
  if (!str) return null;
  const parts = str.split(':');
  const ipn = parts[5];
  return ipn;
};

export const updateLocalStorageSessionTokenIpn = (ipn: string) => {
  let session_token = localStorage.getItem('session_token');
  if(session_token){
      let sessionTokenJSON = JSON.parse(session_token!);
      sessionTokenJSON.ipn = ipn;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
  }
}

export const updateLocalStorageSessionTokenIpnAndStatus = (ipn: string, ipnStatus: string) => {
  let session_token = localStorage.getItem('session_token');
  if(session_token){
      let sessionTokenJSON = JSON.parse(session_token!);
      sessionTokenJSON.ipn = ipn;
      sessionTokenJSON.ipn_status = ipnStatus;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
  }
}

export const updateSessionTokenIpn = (ipn: string) => {
  let sessionToken = localStorage.getItem('session_token');
  let sessionTokenJSON: any;
  if(sessionToken){
      sessionTokenJSON = JSON.parse(sessionToken!);
      sessionTokenJSON.ipn = ipn;
      localStorage.setItem('session_token', JSON.stringify(sessionTokenJSON));
  }
  
  return sessionTokenJSON;
}

/**
 * In most cases we can rely on the IPN value extracted from the claims object ACR string as
 * as the user moves through uplift, however for returning users for the DL who are already IP2
 * and required to pass eligibility this value shows as ip1. In this case we take the ipn value
 * from the user object which inthis scenario reflects the users correct IPN of ip2.
 * 
 * Note, we cant simply use the user objects ipn throughout because it does not always reflect
 * the users accurate IPN and is sometimes even undefined
 *  
 * @param claimsIpnFromAcr the IPN value extracted from claims['http://oauth.tmr.qld.gov.au'].acr
 * @param userObjectIpn the string value of user['http://oauth.tmr.qld.gov.au'].ipn
 * @returns the IPN value to use
 */
export const determineCorrectIpnValue = (claimsIpnFromAcr: string, userObjectIpn: string) => {

  if(Constants.DEBUG) {
    console.log('userObjectIpn', userObjectIpn);
    console.log('claimsIpnFromAcr', claimsIpnFromAcr);
  }

  if (userObjectIpn && userObjectIpn === 'ip2')
    return userObjectIpn;
  else
    return claimsIpnFromAcr

}

/**
 * Compare the users auth0 attrib tandc_accepted date to the date the system
 * Terms and Conditions timestamp to determine if user needs to accept or
 * re-accept the terms and conditions.
 * 
 * If not auth0 attribute exists or not valid, user needs to accept TsCs
 * 
 * @param tandc_accepted_attrib the users auth0 tandc_accepted attribute
 * @returns true if the user needs to accept or re-accept
 */
export const isUserRequiredToAcceptTsCs = (tandc_accepted_attrib: string) => {
  if (!tandc_accepted_attrib) return true;

  const tandc_timestamp = new Date(Constants.TANDC_TIMESTAMP!);
  const users_acceptence_on = new Date(tandc_accepted_attrib);

  if(isNaN(users_acceptence_on.getTime())) return true;

  return (users_acceptence_on < tandc_timestamp);

}

/**
 * @param requested the users requested IP value
 * @param actual the users actual IP value
 * @returns true if user has met or exceeded requested IP
 */
export const hasUserReachedOrSurpassedRequestedACR = (requested: string, actual: string) => {

  requested = requested.toUpperCase();
  actual = actual.toUpperCase();

  if (requested === 'IP1') return true;
  if (requested === 'IP1P' && (actual === 'IP1P' || actual === 'IP2')) return true;
  if (requested === 'IP2' && actual === 'IP2') return true;

  return false;
};


export const FeedbackOptions = new Map<string, string>([
  ['feedback-about-this-website', 'this website'],
  ['feedback-about-a-government-service', 'a government service, department or staff member?'],
]);

export const scrollToTopOptions = {
  top: 0,
  left: 0,
  behavior: "smooth"
};