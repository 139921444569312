import React, { useState, useEffect, useRef } from "react";
import InterstateDriveLicenceType from "./interstate-driver-licence-type";
import Product from "../../product";
import LabelInput from "../../../swe/base/label-input";
import LabelDateInput from "../../../swe/base/label-date-input";
import VerifyYourIdentityConsentAlert from "../../alerts/verifying-identity-consent-alert";
import PrimaryButton from "../../../swe/primary-button";
import SecondaryButton from "../../../swe/secondary-button";
import { useNavigate } from "react-router-dom";
import { CustomValidator, FixedLengthValidator, LengthRangeValidator, MandatoryValidator, MaximumLengthValidator, NonSpaceInputValidator, NotFutureDateValidator, NumbersOnlyValidator, PatternValidator } from "../../../../utils/validation/validator";
import { LabelDateInputValidator } from "../../../../utils/validation/LabelDateInputValidator";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from '../../../../constants';
import { AssociateQDIUserAccountBuilder, DocumentAlreadyVerifiedError, DocumentVerificationError, XBody, verifyInterstateDriversLicence } from "../../../../services/x-api";
import { updateLocalStorageSessionTokenIpnAndStatus, updateSessionTokenIpn } from "../../../../utils/helpers";
import useGlobalState from "../../../../hooks/useGlobalState";
import DocumentVerificationFailedAlert from "../../alerts/document-verification-failed-alert";
import DocumentAlreadyVerifiedAlert from "../../alerts/document-already-verified-alert";
import Loading from "../../../Loading";
import DocumentInvalidAlert from "../../alerts/document-invalid-alert";
import DoBMismatchAlert from "../../alerts/dob-mismatch-alert";

export const InterstateDriveLicenceForm = (props: InterstateDriveLicenceType) => {
    const navigate = useNavigate();
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const { globalState, saveGlobalState } = useGlobalState();

    const [thumbnailURL,setThumbnailURL] = useState('');
    const [stateName,setStateName] = useState('');
    const [dlNumber,setDLNumber] = useState('');
    const [dlNumberValid,setDLNumberValid] = useState(true);
    const [firstName,setFirstName] = useState('');
    const [firstNameValid,setFirstNameValid] = useState(true);
    const [middleName,setMiddleName] = useState('');
    const [middleNameValid,setMiddleNameValid] = useState(true);
    const [lastName,setLastName] = useState('');
    const [lastNameValid,setLastNameValid] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
    const [cardNumber,setCardNumber] = useState('');
    const [cardNumberValid,setCardNumberValid] = useState(true);
    const [isContinueDisabled, setIsContinueDisabled] = useState(true);
    const [counter, setCounter] = useState(0);
    
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [documentAlreadyVerified, setDocumentAlreadyVerified] = useState(false);
    const [documentInvalid, setDocumentInvalid] = useState(false);
    const [dobMismatch, setDobMismatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [unexpectedError, setUnexpectedError] = useState(false);
    const focusRef = useRef(null);
    
    let mandatoryValidator = new MandatoryValidator();
    let dlNumberLengthValidator = props.dlNumberMinLength === props.dlNumberMaxLength ? new FixedLengthValidator(props.dlNumberMaxLength, props.isDLNumberNumeric, true) : (props.dlNumberMinLength > 1 ? new LengthRangeValidator(props.dlNumberMinLength,props.dlNumberMaxLength, props.isDLNumberNumeric) : new MaximumLengthValidator(props.dlNumberMaxLength, props.isDLNumberNumeric));
    let firstNameMaxLengthValidator = new MaximumLengthValidator(props.firstNameMaxLength);
    let middleNameMaxLengthValidator = new MaximumLengthValidator(props.middleNameMaxLength);
    let lastNameMaxLengthValidator = new MaximumLengthValidator(props.lastNameMaxLength);
    let cardNumberLengthValidator = props.cardNumberMinLength === props.cardNumberMaxLength ? new FixedLengthValidator(props.cardNumberMaxLength, props.isCardNumberNumeric, true) : (props.cardNumberMinLength > 1 ? new LengthRangeValidator(props.cardNumberMinLength, props.cardNumberMaxLength, props.isCardNumberNumeric) : new MaximumLengthValidator(props.cardNumberMaxLength, props.isCardNumberNumeric));
    let cardNumbervalidator = props.isCardNumberNumeric ? new NumbersOnlyValidator() : new NonSpaceInputValidator();
    let dlNoNumbersOnlyValidator = props.isDLNumberNumeric ? new NumbersOnlyValidator() : new CustomValidator((value: string) => { return true}, "");
    let namePatternValidator = new PatternValidator(/[^a-zA-Z0-9'‘’\- .]/, "Only the following special characters and symbols are allowed ' . -");
    let allSpacesValidator = new NonSpaceInputValidator();
    let dateInputValidator = new LabelDateInputValidator();
    let futureDateValidator = new NotFutureDateValidator();

    useEffect(() => {
        switch(props.stateCode) {
            case "NSW":
                setStateName('New South Wales');
                setThumbnailURL(require('../../../../assets/images/NSW.png'));
                break;
            case "VIC":
                setStateName('Victorian');
                setThumbnailURL(require('../../../../assets/images/VIC.png'));
                break;
            case "WA":
                setStateName('Western Australian');
                setThumbnailURL(require('../../../../assets/images/WA.png'));
                break;
            case "SA":
                setStateName('South Australian');
                setThumbnailURL(require('../../../../assets/images/SA.png'));
                break;
            case "TAS":
                setStateName('Tasmanian');
                setThumbnailURL(require('../../../../assets/images/TAS.png'));
                break;
            case "ACT":
                setStateName('Australian Capital Territory');
                setThumbnailURL(require('../../../../assets/images/ACT.png'));
                break;
            case "NT":
                setStateName('Northern Territory');
                setThumbnailURL(require('../../../../assets/images/NT.png'));
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
      const isInvalid = validateIsContinueDisabled();
      setIsContinueDisabled(isInvalid);
    }, [counter, dlNumber, firstName, middleName, lastName, dateOfBirth, cardNumber, dlNumberValid, firstNameValid, middleNameValid, lastNameValid]);

    useEffect(() => {
        focusRef?.current?.focus();
    }, []);

    useEffect(() => {
        if(verificationFailed || documentAlreadyVerified || documentInvalid || dobMismatch || unexpectedError) {
            focusRef?.current?.focus();
        }

        if (counter > 4) {
            navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
        }
    }, [verificationFailed, documentAlreadyVerified, documentInvalid, dobMismatch, unexpectedError]);

  
    const validateIsContinueDisabled = () => {
        let areAllFieldsValid = (dlNumberValid && firstNameValid && middleNameValid && lastNameValid && dateOfBirthValid && cardNumberValid);
        let areAllMandatoryFieldsPopulated = (dlNumber.length > 0 && firstName.length > 0 && lastName.length > 0 && dateOfBirth.length > 0 && cardNumber.length > 0 );
        return (!areAllFieldsValid || !areAllMandatoryFieldsPopulated);
    }

    const verifyDocument = async () => {  
        setIsLoading(true);
        setAlertVisibility(false, false, false, false);

        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        if (Constants.DEBUG) { console.log(token, idToken); }

        let builder = new AssociateQDIUserAccountBuilder()
            .withUserId(idToken!.sub)
            .withBirthdate(dateOfBirth)
            .withFamilyName(lastName.trim().replace(/[‘’]/g, "'"))
            .withGivenName(firstName.trim().replace(/[‘’]/g, "'"))
            .withMiddleName(middleName.trim().replace(/[‘’]/g, "'"))
            .withIssuingAuthority(props.stateCode)
            .withDocTypeCode('DL')
            .withLicenceNumber(dlNumber.trim())
            .withCardNumber(cardNumber.trim());
        let envelope = builder.build();

        try {
            await verifyInterstateDriversLicence(props.stateCode.toLowerCase(), envelope, token);
            setAlertVisibility(false, false, false, false);
            setCounter(0);
    
            if(props.ipnLevel === 'ip1p'){
                // Setting localStorage.sessionToken.ipn = "ip2" as a temp fix for
                // ipn_status not getting updated in token issue.
                const updatedSessionToken = updateSessionTokenIpn('ip2');
                saveGlobalState({ sessionToken: updatedSessionToken });
            }
            navigate("/ip-uplift", { replace: true });
        } catch (error) {
            setCounter(counter + 1);
            setIsLoading(false);
            if (error instanceof DocumentVerificationError) {
                // date of birth mismatch test
                if (error.failureCode === "N" && error.failureMessage === "Date of birth not match") {
                    setAlertVisibility(false, false, false, true);
                }
                else if (error.failureCode === "N" && error.failureMessage === "Name not match") {
                    // Setting localStorage.sessionToken.ipn = "ip1p_nc" as a temp fix for
                    // ipn_status not getting updated in token issue.
                    updateLocalStorageSessionTokenIpnAndStatus("ip1p_nc", "nc");
                    navigate("/ip-uplift/name-change-options", { replace: true });
                }
                else if (error.failureCode === "D") {
                    setAlertVisibility(false, true, false, false)
                } 
                else if (error.failureCode === "Document Temporarily Locked") {
                    navigate("/ip-uplift/too-many-attempts?returnCode=8", { replace: true });
                } 
                else if (error.failureCode === "N"){
                    setAlertVisibility(true, false, false, false);
                } 
                else {
                    setUnexpectedError(true);
                    throw error;
                }
            } else if (error instanceof DocumentAlreadyVerifiedError) {
                setAlertVisibility(false, false, true, false);
            }
            else {
                setUnexpectedError(true);
                throw error;
            }
        }
    }

    function setAlertVisibility(verifyFailed: boolean, docInvalid: boolean, docAlreadyVerified: boolean, dobMismtch: boolean){    
        setVerificationFailed(verifyFailed);
        setDocumentInvalid(docInvalid);
        setDocumentAlreadyVerified(docAlreadyVerified);
        setDobMismatch(dobMismtch);
    }

    const goBack = () => {
      navigate("/ip-uplift/verify-your-identity/interstate-dl-selection", { replace: true, state: {ipnLevel: props.ipnLevel} });
    }
    
    return (
        <>
            <div>
                <div tabIndex={-1} ref={focusRef}>
                    {isLoading ? <Loading /> : null}
                    {verificationFailed ? <DocumentVerificationFailedAlert /> : null}
                    {documentAlreadyVerified ? <DocumentAlreadyVerifiedAlert /> : null}
                    {documentInvalid ? <DocumentInvalidAlert /> : null }
                    {dobMismatch ? <DoBMismatchAlert /> : null }
                </div>
                <p>Enter your information exactly as it appears on your {stateName} Driver Licence.</p>
                
                <section className="row cards-identity qg-cards">
                    <Product heading={stateName + " Driver Licence"} media={thumbnailURL} altText="QLD Drivers Licence" action={(e) => { e.preventDefault(); return; }} selected={true} disabled={true} />
                </section>

                <form className="qg-forms-v2">
                    <ol className="questions">
                        <li>
                            <LabelInput 
                                id="driver-licence-number"
                                label={ stateName + " Driver Licence Number"}
                                inputValue={dlNumber}
                                isInputValid={dlNumberValid}
                                setInputValue={setDLNumber}
                                setInputValid={setDLNumberValid}
                                validators={[
                                    mandatoryValidator,
                                    allSpacesValidator,
                                    dlNoNumbersOnlyValidator,
                                    dlNumberLengthValidator
                                ]}
                                mandatory={true}
                                hintPosition="below"
                            />
                        </li>
                        <li>
                            <LabelInput
                                id="first-name"
                                label="First name"
                                inputValue={firstName}
                                isInputValid={firstNameValid}
                                setInputValue={setFirstName}
                                setInputValid={setFirstNameValid}
                                validators={[
                                    mandatoryValidator,
                                    allSpacesValidator,
                                    firstNameMaxLengthValidator,
                                    namePatternValidator
                                ]}
                                persistentHint={<i>If there is no First name printed on the card, a full stop or hyphen must be entered into the First name field.</i>}
                            />
                        </li>
                        <li>
                            <LabelInput
                                id="middle-name"
                                label="Middle name/s"
                                inputValue={middleName}
                                isInputValid={middleNameValid}
                                setInputValue={setMiddleName}
                                setInputValid={setMiddleNameValid}
                                validators={[
                                    allSpacesValidator,
                                    middleNameMaxLengthValidator,
                                    namePatternValidator
                                ]}
                                mandatory={false}
                                persistentHint={props.middleNameInstructionText && props.middleNameInstructionText !== "" ? 
                                    <i>{props.middleNameInstructionText}</i> 
                                    : <i>Middle name/s must be entered if they appear on your document. Separate middle names with a space.</i>}
                            />
                        </li>
                        <li>
                            <LabelInput
                                id="last-name"
                                label="Last name"
                                inputValue={lastName}
                                isInputValid={lastNameValid}
                                setInputValue={setLastName}
                                setInputValid={setLastNameValid}
                                validators={[
                                    mandatoryValidator,
                                    allSpacesValidator,
                                    lastNameMaxLengthValidator,
                                    namePatternValidator
                                ]}
                                persistentHint={<i>If there is no Last name printed on the card, a full stop or hyphen must be entered into the Last name field.</i>}
                            />
                        </li>
                        <li>
                            <LabelDateInput
                                id="date-of-birth" 
                                label="Date of birth" 
                                inputValue={dateOfBirth}
                                isInputValid={dateOfBirthValid}
                                setInputValue={setDateOfBirth}
                                setInputValid={setDateOfBirthValid}
                                validators={[
                                    mandatoryValidator,
                                    dateInputValidator,
                                    futureDateValidator
                                ]}
                            />
                        </li>
                        <li>
                            <LabelInput 
                                id="card-number"
                                label="Card Number"
                                inputValue={cardNumber}
                                isInputValid={cardNumberValid}
                                setInputValue={setCardNumber}
                                setInputValid={setCardNumberValid}
                                validators={[
                                    mandatoryValidator,
                                    cardNumbervalidator,
                                    cardNumberLengthValidator
                                ]}
                                mandatory={true}
                            />
                        </li>
                        <li>
                            <VerifyYourIdentityConsentAlert />
                        </li>
                        <li>
                            <ul className='actions'>
                            <PrimaryButton id="submitButton" heading="Continue" action={verifyDocument} disabled={isContinueDisabled} />
                            <SecondaryButton id="backButton" heading="Back" action={goBack} />
                            </ul>
                        </li>
                    </ol>
                </form>
            </div>    
        </>
    );
}

InterstateDriveLicenceForm.defaultProps = {
    
}

export default InterstateDriveLicenceForm;